/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
//import List from "@material-ui/core/List";
//import ListItem from "@material-ui/core/ListItem";
// core components
//import Header from "components/Header/Header.js";
//import HeaderLinks from "components/Header/HeaderLinks.js";
//import Parallax from "components/Parallax/Parallax.js";
//import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
//import Button from "components/CustomButtons/Button.js";
//import { Link } from "react-router-dom";
// import Footer from "components/Footer/Footer.js";
// sections for this page

//import ToggleVisibilityNavigation from "components/ToggleVisibility/ToggleVisibilityNavigation.js";
//import SectionExamples from "views/PresentationPage/Sections/SectionExamples.js";
//import SectionComponents from "views/PresentationPage/Sections/SectionComponents.js";
//import SectionContent from "views/PresentationPage/Sections/SectionContent.js";


//import SectionDescription from "views/PresentationPage/Sections/SectionDescription.js";
// import SectionVideo from "views/PresentationPage/Sections/SectionVideo.js";
// import SectionCards from "views/PresentationPage/Sections/SectionCards.js";
//import SectionSections from "views/PresentationPage/Sections/SectionSections.js";
//import SectionProducts from "views/PresentationPage/Sections/SectionProducts.js";

//import SectionFreeDemo from "views/PresentationPage/Sections/SectionFreeDemo.js";
//import SectionOverview from "views/PresentationPage/Sections/SectionOverview.js";
//import SectionPricing from "views/PresentationPage/Sections/SectionPricing.js";

//import SectionFooter from "views/PresentationPage/Sections/SectionFooter.js";


import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle.js";

import videoBackground from "assets/video/NFC.mp4";
import AppstoreLogo from "assets/logo/AppstoreLogo.jpg";
import PlaystoreLogo from "assets/logo/PlaystoreLogo.jpg";


import "./style.css";
// import imageScanneQRCODEinREALTIME from "assets/img/81_aad4f689_1_.png";


const useStyles = makeStyles(presentationStyle);


export default function PresentationPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
     {/*  <Header
        brand="inREALTIME"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 700,
          color: "white"
        }}
      /> */}
      <div className="main">
        <div className="overlay"></div>
        <video className="videoCover" src={videoBackground} autoPlay muted loop playsInline />
        <div className={classes.centerContent}>
          <span className={classes.shadowTitle}>
            <span className={classes.proBadge}>NutriFormConcept</span>
          </span>
          <br />
          <h3 className={classes.title}>
          Available soon  <br />on App Store and Play Store</h3>
          <h3 className={classes.title}>
          become the best version of yourself
            </h3>
            <GridContainer>
              <GridItem md={4} sm={4}>
             
              </GridItem>
              <GridItem md={4} sm={4}> 
              <img src={AppstoreLogo} alt="IOS" className={classes.storeBadge} />
              <img src={PlaystoreLogo} alt="ANDROID" className={classes.storeBadge} />
              </GridItem>
              <GridItem md={4} sm={4}>
              </GridItem>
            </GridContainer>
            
                                        
                               
                                
                               
{/*           <ToggleVisibilityNavigation />
 */}        
 </div>
      </div>
      <div className={classNames(classes.main, classes.mainRaised_)}>
        {/* <SectionDescription /> */}
       {/*  <SectionExamples /> */}
        {/* <SectionProducts /> */}


        {/* <SectionVideo /> */}
       {/*  <SectionComponents /> */}
        {/* <SectionCards /> */}
{/*         <SectionContent />
 */}        {/* <SectionSections /> */}
        {/* <SectionExamples /> */}
        {/* <SectionFreeDemo /> */}
        {/* <SectionOverview /> */}
      </div>
{/*       <SectionPricing />
 */}    {/*   <SectionFooter /> */}


      

    </div>
  );
}
